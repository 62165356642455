import { Box, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import RewardsButton from 'ui/rewards/RewardsButton';
// import AdBanner from 'ui/shared/ad/AdBanner';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import UserProfileDesktop from 'ui/snippets/user/profile/UserProfileDesktop';
import UserWalletDesktop from 'ui/snippets/user/wallet/UserWalletDesktop';

import Stats from 'ui/home/Stats';

const BACKGROUND_DEFAULT = 'radial-gradient(103.03% 103.03% at 0% 0%, rgba(183, 148, 244, 0.8) 0%, rgba(110, 204, 217, 0.8) 100%), var(--chakra-colors-blue-400)';
const TEXT_COLOR_DEFAULT = 'white';
const BORDER_DEFAULT = 'none';

const HeroBanner = () => {
  const background = useColorModeValue(
    // light mode
    config.UI.homepage.heroBanner?.background?.[0] ||
    config.UI.homepage.plate.background ||
    BACKGROUND_DEFAULT,
    // dark mode
    config.UI.homepage.heroBanner?.background?.[1] ||
    config.UI.homepage.heroBanner?.background?.[0] ||
    config.UI.homepage.plate.background ||
    BACKGROUND_DEFAULT,
  );

  const textColor = useColorModeValue(
    // light mode
    config.UI.homepage.heroBanner?.text_color?.[0] ||
    config.UI.homepage.plate.textColor ||
    TEXT_COLOR_DEFAULT,
    // dark mode
    config.UI.homepage.heroBanner?.text_color?.[1] ||
    config.UI.homepage.heroBanner?.text_color?.[0] ||
    config.UI.homepage.plate.textColor ||
    TEXT_COLOR_DEFAULT,
  );

  const border = useColorModeValue(
    config.UI.homepage.heroBanner?.border?.[0] || BORDER_DEFAULT,
    config.UI.homepage.heroBanner?.border?.[1] || config.UI.homepage.heroBanner?.border?.[0] || BORDER_DEFAULT,
  );

  return (
    <Flex
      w="100%"
      background={ background }
      border={ border }
      borderRadius="none" // *OhoDesign - base -> none
      p={{ base: 4, lg: 4 }} // *OhoDesign - 8 -> 4
      columnGap={ 8 }
      height={{
        base: '228px',
        lg: '196px', // *OhoDesign
      }} /* OhoDesign - reduce searchbox height */
      alignItems="center"
    >
      <Box
        w="100%"
        background={ config.UI.homepage.plate.background }
        borderRadius={{
          base: 'none',
          lg: 'none',
        }} /*OhoDesign - base: md, lg: xl -> none, none */
        //px={{ base: 4, lg: 10 }}
        //py={{ base: 3, lg: 8 }}
        minW={{ base: 'unset', lg: '900px' }}
        paddingTop={{ base: '12px', lg: '16px' }}
        paddingBottom={{ base: '8px', lg: '12px' }}
        paddingRight={{ base: '0px', lg: '0px' }}
        paddingLeft={{ base: '0px', lg: '0px' }}
        data-label="hero plate"
      >
        <Heading
          as="h1"
          textAlign={{ base: 'center', lg: 'center' }}
          size={{ base: 'md', lg: 'xl' }}
          //fontSize={{ base: '18px', lg: '40px' }}
          lineHeight={{ base: '20px', lg: '32px' }}
          fontWeight={ 500 }
          letterSpacing="1px" // *Ohodesign - add letter spacing
          //color={config.UI.homepage.plate.textColor}
          color="#FFFFFF"
          paddingTop={{ base: '0px', lg: '12px' }} // *Ohodesign - change padding
          paddingBottom={{ base: '0px', lg: '18px' }} // *Ohodesign - change padding
        >
          Layer 1-for-All Blockchain
          { /*{config.meta.seo.enhancedDataEnabled
            ? `${config.chain.name} blockchain explorer`
            : `${config.chain.name} explorer`}*/ }
        </Heading>
        <Flex
          mb={{ base: 0, lg: 0 }} //*Ohodesign - base: 2, lg: 6 -> base: 0, lg: 0
          justifyContent="space-between"
          alignItems="center"
          gridGap={{ base: 1, lg: 2 }}
        >
          <SearchBar isHomepage/>
          { config.UI.navigation.layout === 'vertical' && (
              <Box display={{ base: 'none', lg: 'flex' }} gap={ 2 }>
                { config.features.rewards.isEnabled && <RewardsButton variant="hero"/> }
                  {
                    (config.features.account.isEnabled && <UserProfileDesktop buttonVariant="hero"/>) ||
                          (config.features.blockchainInteraction.isEnabled && <UserWalletDesktop buttonVariant="hero"/>)
                  }
              </Box>
          ) }
        </Flex>
        <Stats/>
      </Box>

      { /*<AdBanner platform="mobile" w="fit-content" flexShrink={ 0 } borderRadius="md" overflow="hidden" display={{ base: 'none', lg: 'block ' }}/>
       */ }   { /*Ohodesign - remove banner */ }
    </Flex> 
  );
};

export default React.memo(HeroBanner);