import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import config from 'configs/app';
import { useScrollDirection } from 'lib/contexts/scrollDirection';
import RewardsButton from 'ui/rewards/RewardsButton';
import NetworkLogo from 'ui/snippets/networkMenu/NetworkLogo';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import UserProfileMobile from 'ui/snippets/user/profile/UserProfileMobile';
import UserWalletMobile from 'ui/snippets/user/wallet/UserWalletMobile';

import Burger from './Burger';

type Props = {
  hideSearchBar?: boolean;
  renderSearchBar?: () => React.ReactNode;
};

const HeaderMobile = ({ hideSearchBar, renderSearchBar }: Props) => {
  const bgColor = useColorModeValue('white', 'black');
  const scrollDirection = useScrollDirection();
  const { ref, inView } = useInView({ threshold: 1 });

  const searchBar = renderSearchBar ? renderSearchBar() : <SearchBar />;

  return (
    <Box
      ref={ref}
      bgColor={bgColor}
      display={{ base: 'block', lg: 'none' }}
      position='sticky'
      top='-1px'
      left={0}
      zIndex='sticky2'
      pt='1px'
    >
      <Flex
        as='header'
        paddingX={4} // *Ohodesign 3 -> 4
        paddingY={2}
        bgColor={bgColor}
        width='100%'
        alignItems='center'
        transitionProperty='box-shadow'
        transitionDuration='slow'
        boxShadow={!inView && scrollDirection === 'down' ? 'md' : 'none'}
      >
        <Burger />
        <Box boxSize={10} />
        {/* Ohodesign - add Box */}
        <NetworkLogo />
        {/* Ohodesign - ml {2} -> deleted, mr="auto" -> deleted */}
        <Flex columnGap={2}>
          {config.features.rewards.isEnabled && <RewardsButton />}
          {(config.features.account.isEnabled && <UserProfileMobile />) ||
            (config.features.blockchainInteraction.isEnabled && (
              <UserWalletMobile />
            )) || <Box boxSize={10} />}
        </Flex>
      </Flex>
      {!hideSearchBar && searchBar}
    </Box>
  );
};

export default React.memo(HeaderMobile);
