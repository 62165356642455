import { Text, Flex, Box, Skeleton, useColorModeValue } from '@chakra-ui/react';
import type { UseQueryResult } from '@tanstack/react-query';
import React from 'react';

import type { HomeStats } from 'types/api/stats';
import type { ChainIndicatorId } from 'types/homepage';

import type { ResourceError } from 'lib/api/resources';

interface Props {
  id: ChainIndicatorId;
  title: string;
  value: (stats: HomeStats) => string;
  // valueDiff?: (stats?: HomeStats) => number | null | undefined;
  // icon: React.ReactNode;
  isSelected: boolean;
  onClick: (id: ChainIndicatorId) => void;
  stats: UseQueryResult<HomeStats, ResourceError<unknown>>;
}

const ChainIndicatorItem = ({ id, title, /*value, valueDiff, icon,*/isSelected, onClick, /*stats*/ }: Props) => {
  /* Ohodesign - remove items from ChainIndicatorItem */
  const activeColor = useColorModeValue('white', 'gray.900'); // *Ohodesign - gray.400 => gray.900
  const activeBgColor = useColorModeValue('white', 'black');

  const handleClick = React.useCallback(() => {
    onClick(id);
  }, [ id, onClick ]);

  /*const valueContent = (() => {
    if (!stats.data) {
      return <Text variant="secondary" fontWeight={ 400 }>no data</Text>;
    }

    return (
      <Skeleton isLoaded={ !stats.isPlaceholderData } variant="secondary" fontWeight={ 600 } minW="30px">
        { value(stats.data) }
      </Skeleton>
    );
  })();

  const valueDiffContent = (() => {
    if (!valueDiff) {
      return null;
    }
    const diff = valueDiff(stats.data);
    if (diff === undefined || diff === null) {
      return null;
    }

    const diffColor = diff >= 0 ? 'green.500' : 'red.500';

    return (
      <Skeleton isLoaded={ !stats.isPlaceholderData } ml={ 1 } display="flex" alignItems="center" color={ diffColor }>
        <span>{ diff >= 0 ? '+' : '-' }</span>
        <Text color={ diffColor } fontWeight={ 600 }>{ Math.abs(diff) }%</Text>
      </Skeleton>
    );
  })(); */

  return (
    <Flex
      alignItems="center"
      columnGap={ 3 } // *OhoDesign - change from { 2 } to { 3 }
      // flexGrow={{ base: 0, lg: 1 }} // *OhoDesign - remove flexGrow
      px="8px" // *OhoDesign - change from {{ base: '6px', lg: 2 }} to "8px"
      py="8px" // *OhoDesign - change from {2} to "8px"
      as="li"
      height="24px" // *OhoDesign - indicate height
      borderRadius="base"
      cursor="pointer"
      onClick={ handleClick }
      color={ isSelected ? activeColor : 'link' }
      //bgColor={ isSelected ? activeBgColor : undefined } *OhoDesign - channge bgColor
      bgColor={ isSelected ? '#b29700' : 'ffffff' }
      border="2px solid"
      borderColor={ isSelected ? '#b29700' : '#d4af37' }

      fontSize="xs"
      fontWeight={ 500 }
      _hover={{
        // bgColor: activeBgColor,  *OhoDesign - remove hover color
        activeBgColor,
        // color: isSelected ? activeColor : 'link_hovered', *OhoDesign - remove hover color
        zIndex: 1,
      }}
    >
      { /*{ icon }*/ } { /* OhoDesign - remove icon */ }
      <Box textColor={ isSelected ? '#FFFFFF' : '#d4af37' }>
        <span>{ title }</span>
        { /*<Flex alignItems="center" color="text" >
          { valueContent }
          { valueDiffContent }
        </Flex> */ }
        { /* OhoDesign - remove content and Diff*/ }
      </Box>
    </Flex>
  );
};

export default React.memo(ChainIndicatorItem);
